import React, { useState, useEffect } from 'react';
import moment from 'moment';

// import "../../../../css/main.css"
import { Header } from '../../../../components/header.js';
import { Selector } from '../../../../components/parker/select-spot';

const queryService = 'https://backend.demo.parqr.app:8443/graphql/';

const LotPage = (props) => {

  const lotName = props.lotName;
  const maxNumSpots = 50; //FIX: should bring in actual number of spots here 

  // query vals
  const [names, setNames] = useState([]);
  const [expirys, setExpirys] = useState(new Array(maxNumSpots).fill(moment()));

  const lotQuery = `query lotQuery($lotName: ID!) {
    lots(name: $lotName) {
  		name
    	spots {
      	name
      	endTime
    }
    }
  }`;

  // sets initial state on page load or after success
  useEffect(() => {
    return fetch(queryService, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify({
        query: lotQuery,
        variables: { lotName: lotName },
      })
    })
      .then(r => r.json())
      .then(data => {
        console.log(data.data.lots[0].spots.name)
        let spotNames = [];
        let spotExpirys = [];
        for (var i = 0; i < data.data.lots[0].spots.length; i++) {
          spotNames.push(data.data.lots[0].spots[i].name);
          spotExpirys.push(moment(data.data.lots[0].spots[i].endTime));
        }
        setNames(spotNames);
        setExpirys(spotExpirys);
      })
  }, [])


  return (
    <React.Fragment>
      <Header />
      <Selector
        lotName={lotName}
        names={names}
        expirys={expirys}
        numSpots={names.length}
      />
    </React.Fragment>
  )
};

export default LotPage
